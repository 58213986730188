<template>
  <div
    ref="productSpot"
    class="productspot h-full flex flex-wrap relative"
    :class="{
      'bg-grey200 rounded-md': big,
    }"
  >
    <div class="w-full">
      <div
        class="relative"
      >
        <div
          v-if="!slimLayout && product.category.productListingUrl"
          class="absolute top-12 left-12 z-20 flex items-start flex-wrap"
        >
          <div v-if="props.subscription">
            <GlobalsLinkHelper
              v-for="flag in subscriptionFlags"
              :key="`productSpotFlag-${flag.name}-${product.id}`"
              :to="`${product.category.productListingUrl}${flag.urlSegment}/`"
              rel="nofollow"
              class="block xsOnly:px-8 leading-[10px] px-12 pb-4 pt-6 mr-8 last:mr-0 mb-6 text-[10px] text-xsmall-medium w-fit-content bg-white rounded-full border border-grey400 animate-all hover:border-grey800"
            >
              {{ flag.name }}
            </GlobalsLinkHelper>
          </div>
          <GlobalsLinkHelper
            v-for="flag in flags"
            v-else
            :key="`productSpotFlag-${flag.name}-${product.id}`"
            :to="`${product.category.productListingUrl}${flag.urlSegment}/`"
            rel="nofollow"
            class="block xsOnly:px-8 leading-[10px] px-12 pb-4 pt-6 mr-8 last:mr-0 mb-6 text-[10px] text-xsmall-medium w-fit-content bg-white rounded-full border border-grey400 animate-all hover:border-grey800"
          >
            {{ flag.name }}
          </GlobalsLinkHelper>
        </div>
        <GlobalsLinkHelper
          :to="product.url + (subscription ? '?cartType=Subscription' : '')"
          class="bg-grey200 rounded-md flex items-center justify-center aspect-square mb-12 sm:mb-16 w-full relative"
          :class="{
            'aspect-4/3 pt-48': big && !slimLayout
          }"
          @click="productClick()"
        >
          <nuxt-img
            v-if="product.imageUrl"
            :preload="shouldPreload ? true : false"
            provider="norce"
            :src="product.imageUrl"
            preset="productImage"
            :index="spotIndex"
            :sizes="product.productType === 'Bundle' ? '240px md:297px' : 'sm:130px md:300px'"
            :loading="load"
            :fetchpriority="fetch"
            :alt="product.name"
            height="130px"
            width="130px"
            class="product-image aspect-square object-contain max-w-2/3"
            :class="{
              'w-[215px] xs:w-[300px] sm:w-[215px]': big || bigTransparent,
              '!w-[72px] xs:!w-[126px]': slimLayout,
              'w-[112px] xs:w-[185px]': !big && !slimLayout,
              'w-[112px] sm:w-[126px]': smallLayout && !big,
              '!w-full !max-w-full rounded-md': product.productType === 'Bundle' && !big,
              'md:!w-[112px]': searchLayout
            }"
          />
          <FiveDots
            v-if="getStrength(product)"
            title="Styrka:"
            :dots="getStrength(product)"
            class="absolute left-16"
            :class="{
              '-bottom-[15px]': big,
              'bottom-12': !big,
              'hidden sm:block': slimLayout
            }"
          />
        </GlobalsLinkHelper>
        <div
          class="absolute right-16 bg-white rounded-full h-28 w-28 sm:h-32 sm:w-32 animate-all border border-transparent hover:border-grey800 cursor-pointer bookmark tooltip group"
          :class="{
            '-bottom-[15px]': big,
            'bottom-12': !big,
            'hidden sm:block': slimLayout
          }"
          @click="toggleFavourites(product.partNo)"
        >
          <span
            class="opacity-0 pointer-events-none w-[100px] py-4 bg-black text-white text-center rounded-md absolute z-10 tooltip-top group-hover:opacity-100 animate-all text-[10px] top-0 left-0 transform -translate-x-[55%] -translate-y-full -mt-8 hidden lg:block"
          >
            <span v-if="!isAddedToFavourites">{{ productResources.addToFavourites }}</span>
            <span v-else>{{ productResources.removeFromFavourites }}</span>
          </span>
          <BookmarkIcon class="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          <transition name="fade" mode="out-in">
            <BookmarkIconSolid v-if="isAddedToFavourites" class="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          </transition>
        </div>
      </div>
      <div
        class="w-full"
        :class="{
          'p-24 px-16 pb-0': big,
        }"
      >
        <GlobalsLinkHelper
          :to="product.url + (subscription ? '?cartType=Subscription' : '')"
          class="w-full"
          @click="productClick()"
        >
          <h3
            class="!mb-4 !sm:mb-8 !font-dinPro"
            :class="{
              'text-small-medium': big,
              'text-tiny-medium text-[12px] sm:text-xsmall-medium': slimLayout || searchLayout,
              'text-xsmall-medium sm:text-small-medium': (!slimLayout || !searchLayout) && !big,
              'pb-24': big && !showExcerpt && !showQuickbuy
            }"
          >
            {{ product.name }}
          </h3>
        </GlobalsLinkHelper>
        <div
          v-if="showExcerpt && description"
          ref="descriptionWrapper"
          class="wysiwyg wysiwyg--tiny-text mb-16 relative max-h-[75px] overflow-hidden"
          :class="{
            'gradient' : showGradient,
            'gradient--grey': big
          }"
          v-html="description"
        />
      </div>
    </div>
    <div
      v-if="showQuickbuy"
      class="quickBuy mt-auto w-full flex lg:flex-row"
      :class="{
        'px-16 pb-32 flex-row sm:flex-col lg:flex-row': big,
        'flex-col lg:flex-col': smallLayout && !big && !bigTransparent || subscription || isMobile && !big && !bigTransparent,
        'lg:flex-col xl:flex-col': subscription
      }"
    >
      <SelectorVariants
        v-if="!allVariantsOutOfStock || useRunnerStockStatus || (isLoggedIn && onlyAvailableInStore)"
        class="quickBuyAmount w-full mb-8 mr-8"
        :items="subscription ? product.subscribableVariants : product.variants"
        :is-runner="product.isRunner"
        :type="product.productType"
        :text-size="isMobile && !big ? 'text-xsmall-medium':'text-small-medium'"
        :simple-product-listing="simpleProductListing"
        @change="setVariant"
      />
      <AnimatedBuyButton
        v-if="!allVariantsOutOfStock || useRunnerStockStatus"
        class="quickBuyBuy btn btn--primary flex-shrink-0 btn--xs h-36 sm:h-40"
        :button-text="subscription ? productListingResources.subscribe : productListingResources.buy"
        :product="product"
        :selected-variant="selectedVariant"
        :cart-type="subscription ? CartType.Subscription : CartType.Standard"
      />
      <div v-if="allVariantsOutOfStock && !useRunnerStockStatus" class="w-full transform">
        <SelectorVariants
          v-if="smallLayout"
          class="quickBuyAmount w-full mb-8 mr-8 opacity-60 pointer-events-none cursor-pointer lg:hidden"
          :class="{
            'lg:!block': isProductBlock && !big
          }"
          :items="subscription ? product.subscribableVariants : product.variants"
          :is-runner="product.isRunner"
          :type="product.productType"
          :text-size="isMobile && !big ? 'text-xsmall-medium':'text-small-medium'"
          :simple-product-listing="simpleProductListing"
        />
        <nuxt-link
          :to="product.url"
          class="btn btn--secondary btn--sm-text flex-shrink-0 btn--xs h-36 sm:h-40 w-full"
          :class="{
            'lg:mb-8': !isProductBlock
          }"
        >
          {{ productResources.getNotified }}
        </nuxt-link>
        <div
          class="text-xsmall absolute bottom-0 text-xsmall-medium left-0 -mb-24 md:-mb-[32px] text-[10px] sm:text-[12px] lg:-mb-24"
          :class="{
            'left-0 text-[10px] sm:text-[12px]': !big,
          }"
        >
          <div class="h-8 w-8 mr-4 text-xsmall-medium inline-block bg-error rounded-full" />
          {{ productResources.outOfStock }}
        </div>
      </div>
    </div>
    <div
      v-if="selectedVariant && !selectedVariant.inStock && useRunnerStockStatus && selectedVariant.nextDeliveryDate && showQuickbuy"
      class="absolute bottom-0 text-xsmall-medium"
      :class="{
        'left-0 -mb-24 md:-mb-[22px] text-[10px] sm:text-[12px]': !big,
        'left-24 mb-12': big,
      }"
    >
      <div class="h-8 w-8 mr-4 text-xs-medium inline-block bg-accent rounded-full" />
      {{ productResources.isSent }}
      {{ getLocaleDate(selectedVariant.nextDeliveryDate, { month: 'long', day: 'numeric', year: 'numeric' }) }}
    </div>
    <div
      v-if="onlyAvailableInStore && isLoggedIn && showQuickbuy && checkoutSettings.enableClickAndCollect"
      class="absolute bottom-0 text-xsmall-medium"
      :class="{
        'left-0 -mb-24 md:-mb-[22px] text-[10px] sm:text-[12px]': !big,
        'left-24 mb-12': big,
      }"
    >
      <div class="h-8 w-8 mr-4 text-xs-medium inline-block bg-accent rounded-full" />
      {{ productResources.collectInStoreOnly }}
    </div>
  </div>
</template>
<script setup lang="ts">
import type { IProduct, IVariant, ITag, IFlag } from '~/api-types';

import SelectorVariants from '~/components/form-elements/SelectorVariants.vue';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { computed, ref, type Ref, watch } from 'vue';
import AnimatedBuyButton from '~/components/form-elements/AnimatedBuyButton.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import FiveDots from '~/components/product/FiveDots.vue';
import useProduct from '~/composables/useProduct';
import BookmarkIcon from '@heroicons/vue/24/outline/esm/BookmarkIcon';
import BookmarkIconSolid from '@heroicons/vue/24/solid/esm/BookmarkIcon';
import { useFavouritesStore } from '~/store/favourites';
import useLocalDate from '~/composables/useLocalDate';
import { useVoyadoStore } from '~/store/voyado';
import { CartType } from '~/api-types';

const globalContentStore = useGlobalContentStore();
const productResources = globalContentStore.productResources;
const productListingResources = globalContentStore.productListingResources;
const marketSettings = globalContentStore.marketSettings;
const checkoutSettings = globalContentStore.checkoutSettings;
const productSettings = useGlobalContentStore().productSettings;
const { isMobile } = storeToRefs(useUiStore());
const favouritesStore = useFavouritesStore();
const addedFavourites: Ref<string[]> = ref([]);
const uiStore = useUiStore();
const { getLocaleDate } = useLocalDate();
const descriptionWrapper = ref();
const showGradient = ref(true);
const productSpot = ref();
const voyadoStore = useVoyadoStore();
const route = useRoute();
const { isLoggedIn } = storeToRefs(uiStore);

addedFavourites.value = favouritesStore.favourites;

const setVariant = (variant: IVariant) => {
  selectedVariant.value = variant;
};

const props = defineProps<{
  product: IProduct,
  showExcerpt?: boolean,
  showQuickbuy?: boolean,
  smallLayout?: boolean,
  slimLayout?: boolean,
  big?: boolean,
  bigTransparent?: boolean,
  subscription?: boolean,
  searchLayout?: boolean,
  spotIndex?: number,
  load?: string,
  fetch?: string,
  flagsToHide?: string,
  shouldPreload?: boolean,
  isProductBlock?: boolean,
  simpleProductListing?: boolean
}>();

const emit = defineEmits<{
  (event: 'remove-favourite', partNo: string): void,
}>();

const selectedVariant = ref<IVariant>(props.product.variants[0]);

const { getStrength } = useProduct();

const allVariantsOutOfStock = computed<boolean>(()=> {
  let hasVariantInStock = false;
  props.product.variants?.forEach((variant: IVariant) => {
    // If click an collect is disabled, use inStock
    if (!checkoutSettings.enableClickAndCollect) {
      if (variant && variant.inStock) {
        hasVariantInStock = true;
      }
    }

    if (variant.availability.online) hasVariantInStock = true;

    if (variant.availability.inStore && !variant.availability.online && isLoggedIn.value) {
      hasVariantInStock = true;
    }

    if (variant.inStock && props.subscription) {
      hasVariantInStock = true;
    }
  });
  return !hasVariantInStock;
});

const flags = computed<IFlag[] | null>(() => {
  return props.product.flags.filter((item : IFlag) => {
    return props.product.tags.some((tag : ITag) => tag.urlSegment === item.urlSegment);
  });
});

const subscriptionFlags = computed<IFlag[] | null>(() => {
  return props.product.flags.filter((item : IFlag) => {
    return props.product.tags.some((tag : ITag) => tag.urlSegment === item.urlSegment && !props.flagsToHide?.includes(item.urlSegment));
  });
});

const isAddedToFavourites = computed(() => {
  return addedFavourites.value && addedFavourites.value.includes(props.product.partNo);
});

const description = computed(() => {
  return props.product.shortDescription ?? props.product.description;
});

const onlyAvailableInStore = computed(() => {
  if (!checkoutSettings.enableClickAndCollect) return selectedVariant.value.inStock;
  return selectedVariant.value.availability.inStore && !selectedVariant.value.availability.online;
});

const useRunnerStockStatus = computed(() => {
  return props.product.isRunner && !productSettings.disablePurchaseIfOutOfStock;
});

const setShowGradient = () => {
  if (descriptionWrapper.value)  {
    showGradient.value = descriptionWrapper.value.offsetHeight > 60;
  }
};

onMounted(()=> {
  setShowGradient();
  onProductImpression();
});

const addToFavourites = (async(partNo: string) => {
  if (uiStore.isIdentifiedContact) {
    addedFavourites.value.push(partNo);
    await favouritesStore.addToFavourites(partNo);

    window.dataLayer?.push({
      'event': 'addToFavList',
      'favProductName': props.product.name,
    });
  } else {
    onOpenUserModal(partNo);
  }
});

const removeFromFavourites = (async(partNo: string) => {
  const index = addedFavourites.value.indexOf(partNo);
  if (index !== -1) {
    addedFavourites.value.splice(index, 1);
  }
  await favouritesStore.removeFromFavourites(partNo);
  emit('remove-favourite', partNo);
});

const toggleFavourites = (async(partNo: string) => {
  if (addedFavourites.value.includes(partNo)) {
    removeFromFavourites(partNo);
  } else {
    addToFavourites(partNo);
  }
});

const onOpenUserModal = (partNo: string) => {
  uiStore.setShowLogin(true);
  uiStore.setPreventLoginNavigate(true);
  uiStore.setSavedPartNo(partNo);
};

watch(() => favouritesStore.favourites, 
  () => {
    addedFavourites.value = favouritesStore.favourites;
  },
  { deep: true }
);

const onProductImpression = async() => {
  await nextTick();

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          window.dataLayer?.push({
            event: 'productImpressions',
            ecommerce: {
              currencyCode: marketSettings.currency.currencyCode,
              impressions: [
                {
                  list: route.fullPath,
                  brand: props.product.brand?.name,
                  category: props.product.category?.name,
                  id: props.product.partNo,
                  name: props.product.name,
                  price: selectedVariant?.value ? selectedVariant?.value.price.value : 0,
                  variant: props.product.productType === 'Bundle' ?
                    '5-pack' :
                    selectedVariant?.value ? selectedVariant?.value.packageSize : '1' + '-pack',
                  quantity: 1,
                },
              ],
            },
          });

          if (selectedVariant?.value && !selectedVariant?.value.inStock) {
            window.dataLayer?.push({
              event: 'outOfStock',
              productName: props.product.name,
              sku: props.product.partNo,
            });
          }

          if (selectedVariant.value && props.product.category?.name && selectedVariant.value.partNo) {
            voyadoStore.sendProductView(props.product.category?.name, selectedVariant.value.partNo);
          }
          
          if (productSpot.value) {
            observer.unobserve(productSpot.value as Element);
          }
        }
      });
    },
    { rootMargin: '0px', threshold: 0.5, root: null }
  );
  if (productSpot.value) {
    observer.observe(productSpot.value as Element);
  }
};

const productClick = () => {
  window.dataLayer?.push({
    event: 'productClick',
    ecommerce: {
      currencyCode: marketSettings.currency.currencyCode,
      click: {
        actionField: {
          list: route.fullPath,
        },
        products: [
          {
            brand: props.product.brand?.name,
            category: props.product.category?.name,
            id: props.product.partNo,
            name: props.product.name,
            price: selectedVariant?.value ? selectedVariant?.value.price.value : 0,
            variant: props.product.productType === 'Bundle' ?
              '5-pack' :
              selectedVariant?.value ? selectedVariant?.value.packageSize : '1' + '-pack',
            quantity: 1,
          },
        ],
      },
    },
  });
};

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Product',
  name: props.product?.name,
  image: props.product?.imageUrl,
  description: props.product?.description,
  url : props.product?.url,
  sku : props.product?.id,
  mpn: selectedVariant.value?.partNo,
  brand: {
    '@type': 'Thing',
    name: props.product?.brand ? props.product?.brand[0]?.name : '',
  },
  'offers': {
    '@type' : 'Offer',
    price : selectedVariant.value?.price?.value,
    priceCurrency : selectedVariant.value?.price?.currencyCode,
    availability: selectedVariant.value && selectedVariant.value?.inStock ? 'InStock' : 'OutOfStock',
  },
};

useJsonld(jsonLd);

</script>

<style lang="postcss" scoped>
.productspot {
  /* container-type: inline-size; */
  container-name: productspot;
}
@container productspot (min-width: 245px) {
  .quickBuy {
    @apply flex-row;
  }
  .quickBuyAmount {
    @apply mr-8;
  }
}

.gradient {
  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255,255,255,1) 0%,
      rgba(255,255,255,0) 100%
    );
    background-color: rgba(255, 255, 255, 0);
    height: 60%;
    max-height: 50px;
    @apply absolute bottom-0 left-0 w-full pointer-events-none;
  }

  &--grey {
    &::after {
      background: linear-gradient( 0deg, rgba(240,242,243,1) 0%, rgba(255,255,255,0) 100% );
    }
  }
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 24px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
</style>
